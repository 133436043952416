// NOT IN USE!!!!!

import React from 'react'
import ContentGroupView from './content-group-view'

function ContentGroupController() {
  // TODO: load top level content group data

  return (
    <ContentGroupView />
  )
}

export default ContentGroupController
